import http from '@/axios'
import { IPackages } from '@/interfaces/BaseInterface';
import AuthService from "@/classes/AuthService";

const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString()
// const TOKEN_PARAM_OBJECT = AuthService.getHybrainTokenQueryString()

class Packages implements IPackages {
    
    indexPackages(data: any) {
        return http.get(`packages/index?${data}&${TOKEN_PARAM_STRING}`);
    }
    
    showPackage(name: string) {
        return http.get(`packages/edit?name=${name}&${TOKEN_PARAM_STRING}`);
    }

    showSchedule(name: string) {
        return http.get(`packages/schedule/show?package_name=${name}&${TOKEN_PARAM_STRING}`);
    }

    updateSchedule(payload: any) {
        return http.post(`packages/schedule`, payload);
    }

    updatePackage(payload: any) {
        return http.post(`packages/update/`, payload);
    }
}

export default new Packages();
