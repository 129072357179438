import http from '@/axios'
import { IBranch } from '@/interfaces/BaseInterface';

class Branch implements IBranch {
    
    indexBranch(data: any) {
        return http.get(`branches/index?${data}`);
    }

    createBranch() {
        return http.get(`branches/create`);
    }

    storeBranch(payload: object) {
        return http.post(`branches/store`, payload);
    }
    
    editBranch(id: string) {
        return http.get(`branches/edit/${id}`);
    }

    updateBranch(branchId: string, payload: object) {
        return http.post(`branches/update/${branchId}`, payload);
    }

    deleteBranch(id: string) {
        return http.delete(`branches/delete/${id}`);
    }

    restoreBranch(id: string) {
        return http.post(`branches/restore`, {id: id});
    }

    exportBranch(payload: string) {
        return http.get(`branches/export?${payload}`);
    }

    updateTimeSlot(branchId: string, payload: object) {
        return http.post(`branches/update-time-slot/${branchId}`, payload);
    }

    formatData(form: any, branchId: any) {
        const data = new FormData();
        data.append('image_path', form.image_path);
        data.append('name', form.name);
        data.append('address_line', form.address_line);
        data.append('region', form.region);
        data.append('region_code', form.region_code);
        data.append('province', form.province);
        data.append('province_code', form.province_code);
        data.append('city', form.city);
        data.append('city_code', form.city_code);
        data.append('barangay', form.barangay);
        data.append('barangay_code', form.barangay_code);
        data.append('zip_code', form.zip_code);
        data.append('mobile_number', form.mobile_number);
        data.append('start_time', form.start_time);
        data.append('end_time', form.end_time);
        data.append('id', branchId);
        data.append('time_slot', form.time_slot);
        return data;
    }
}

export default new Branch();
