import http from '@/axios'
import { IVoucher } from '@/interfaces/BaseInterface';

class Voucher implements IVoucher {
    
    indexVoucher(data: any) {
        return http.get(`vouchers/index?${data}`);
    }

    createVoucher() {
        return http.get(`vouchers/create`);
    }

    storeVoucher(payload: object) {
        return http.post(`vouchers/store`, payload);
    }
    
    editVoucher(id: string) {
        return http.get(`vouchers/edit/${id}`);
    }

    updateVoucher(voucherId: string, payload: object) {
        return http.post(`vouchers/update/${voucherId}`, payload);
    }

    cancelVoucher(id: string) {
        return http.post(`/vouchers/cancel/${id}`);
    }

    deleteVoucher(id: string) {
        return http.delete(`vouchers/delete/${id}`);
    }

    restoreVoucher(id: string) {
        return http.post(`vouchers/restore`, {id: id});
    }

    exportVoucher(payload: string) {
        return http.get(`vouchers/export?${payload}`);
    }

    formatData(form: any, voucherId: any) {
        const data = new FormData();
        data.append('name', form.name);
        data.append('code', form.code);
        data.append('information', form.information);
        data.append('usages', form.usages);
        data.append('validity_range', form.validity_range);
        data.append('status', form.status);
        data.append('id', voucherId);
        return data;
    }
}

export default new Voucher();
