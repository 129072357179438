import http from '@/axios'
import { IBooking } from '@/interfaces/BaseInterface';
import AuthService from "@/classes/AuthService";

const TOKEN_PARAM_OBJECT = AuthService.getHybrainTokenObject();
const TOKEN_PARAM_STRING = AuthService.getHybrainTokenQueryString();

class BookingService implements IBooking {

    submitCorporateBooking(data: object) {
        return http.post(`corporate-booking/submit-booking`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    fetchCorporateBooking(corporateBookingId: string) {
        // return http.post(`corporate-booking/fetch-booking`, Object.assign(data, TOKEN_PARAM_OBJECT));
        return http.get(`corporate-booking/fetch-booking/` + corporateBookingId + `?${TOKEN_PARAM_STRING}`);
    }

    updateCorporatePatientsList(data: object) {
        return http.post(`corporate-booking/update-patients-list`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    submitIndividualBooking(data: object) {
        return http.post(`individual-booking/submit-booking`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    submitSoftBooking(data: object) {
        return http.post(`individual-booking/submit-soft-booking`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    fetchSoftBooking(bookingId: string) {
        return http.get(`bookings/fetch-soft-booking/` + bookingId + `?${TOKEN_PARAM_STRING}`);
    }

    updateSoftBooking(data: object) {
        return http.post(`bookings/update-soft-booking`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    fetchDateAndTime(data: object) {
        return http.post(`corporate-booking/fetch-date-and-time`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    fetchIndividualBooking(bookingId: string) {
        return http.get(`bookings/fetch-selected-appointment/` + bookingId + `?${TOKEN_PARAM_STRING}`);
    }

    updatePatientEmail(data: object) {
        return http.post(`bookings/update-patient-email`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    submitCancelBooking(type: string, data: object) {
        if(type == 'Individual' || type == 'individual') {
            return http.post(`individual-booking/cancel-booking`, Object.assign(data, TOKEN_PARAM_OBJECT));
        } else {
            return http.post(`corporate-booking/cancel-booking`, Object.assign(data, TOKEN_PARAM_OBJECT));
        }
    }

    submitMarkNoShow(type: string, data: object) {
        if(type == 'Individual' || type == 'individual') {
            return http.post(`individual-booking/mark-as-no-show`, Object.assign(data, TOKEN_PARAM_OBJECT));
        } else {
            return http.post(`corporate-booking/mark-as-no-show`, Object.assign(data, TOKEN_PARAM_OBJECT));
        }
    }

    submitCancelSpecific(type: string, data: object) {
        if(type == 'Individual' || type == 'individual') {
            return http.post(`individual-booking/cancel-specific-booking`, Object.assign(data, TOKEN_PARAM_OBJECT));
        } else {
            return http.post(`corporate-booking/cancel-specific-booking`, Object.assign(data, TOKEN_PARAM_OBJECT));
        }
    }

    submitMarkAsDone(type: string, data: object) {
        if (type === 'Individual' || type === 'individual') {
            return http.post(`individual-booking/mark-as-done`, Object.assign(data, TOKEN_PARAM_OBJECT));
        } else {
            return http.post(`corporate-booking/mark-as-done`, Object.assign(data, TOKEN_PARAM_OBJECT));
        }
    }
    

    rescheduleBooking(type: string, data: object) {
        if(type == 'Individual' || type == 'individual') {
            return http.post(`individual-booking/reschedule-booking`, Object.assign(data, TOKEN_PARAM_OBJECT));
        } else {
            return http.post(`corporate-booking/reschedule-booking`, Object.assign(data, TOKEN_PARAM_OBJECT));
        }
    }

    getPackages() {
        return http.get(`bookings/fetch-packages`);
    }

    getServices() {
        return http.get(`bookings/fetch-services`);
    }
    
    getAllergyTests() {
        return http.get(`bookings/fetch-allergy-tests`);
    }

    getPackagesAndRiders() {
        return http.get(`bookings/fetch-packages-and-riders?${TOKEN_PARAM_STRING}`);
    }

    exportBooking(payload: string) {
        return http.post(`bookings/export?${payload}&${TOKEN_PARAM_STRING}`);
    }

    uploadNoteAndResult(data: object) {
        return http.post(`bookings/upload-note-and-result`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    fetchNoteAndResult(bookingId: string) {
        return http.get(`bookings/fetch-note-and-result/` + bookingId + `?${TOKEN_PARAM_STRING}`);
    }

    resendEmail(data: object) {
        return http.post(`corporate-booking/resend-email`, Object.assign(data, TOKEN_PARAM_OBJECT));
    }

    fetchScheduleLogs(type: string, bookingId: string) {
        if(type == 'Individual' || type == 'individual') {
            return http.get(`individual-booking/fetch-schedule-logs/` + bookingId + `?${TOKEN_PARAM_STRING}`);
        } else {
            return http.get(`corporate-booking/fetch-schedule-logs/` + bookingId + `?${TOKEN_PARAM_STRING}`);
        }
    }

    getGetAvailableDates(payload: object) {
        return http.post(`individual-booking/fetch-available-dates`, payload);
    }
}
export default new BookingService();